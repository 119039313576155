import { ReactNode, useCallback } from "react";
import {
  LibraryCourseFragment,
  LibraryContainerLibraryItemFragment,
  LibraryPathFragment,
  LibrarySkillFragment,
  LibraryTrainingResourceFragment,
} from "../LibraryContainer.generated";
import {
  getLibraryItemArchivedAt,
  getLibraryItemContentType,
  getLibraryItemEditedAt,
  getLibraryItemOwnerId,
  getLibraryItemSharingPermissions,
  getLibraryItemTitle,
  isSpecialContent,
  userHasEditPermissions,
} from "../utils";
import AutoLayout from "@src/deprecatedDesignSystem/components/AutoLayout";
import Text from "@ui/text";
import { deprecatedTones } from "@src/deprecatedDesignSystem/styles/deprecatedColors";
import { CoverImage, UserType } from "@src/types.generated";
import { PUBLISH_STATE_COPY } from "@src/utils/courses";
import LibraryItemTagCell from "../LibraryItemTagCell";
import { useFormattedDateInTable } from "@src/components/contentDetail/shared/useFormattedDateInTable";
import ContentAvatar, {
  ContentAvatarSize,
} from "@src/deprecatedDesignSystem/components/ContentAvatar";
import useUser from "@src/hooks/useUser";
import CourseOverflowMenu from "../CourseOverflowMenu";
import PathOverflowMenu from "../PathOverflowMenu";
import SkillOverflowMenu from "../SkillOverflowMenu";
import TrainingResourceOverflowMenu from "../TrainingResourceOverflowMenu";
import {
  getOrgPremiumContentConfigFragmentStatus,
  getOrgSharpConfigStatus,
} from "@src/utils/premiumPaths";
import {
  LibraryItemTitleFragment,
  LibraryItemAvatarFragment,
} from "../utils.generated";

type Return = {
  renderTitleAndSubtitle: (
    x: LibraryItemTitleFragment,
    subtitle?: string,
  ) => ReactNode;
  renderPublishedStatus: (
    x: LibraryContainerLibraryItemFragment,
  ) => string | null;
  renderTags: (x: LibraryContainerLibraryItemFragment) => ReactNode;
  renderLastEdited: (x: LibraryContainerLibraryItemFragment) => ReactNode;
  renderArchivedAt: (x: LibraryContainerLibraryItemFragment) => ReactNode;
  renderAvatar: (
    x: LibraryItemAvatarFragment,
    size?: ContentAvatarSize,
  ) => ReactNode | undefined;
  renderOverflowMenu: (
    x: LibraryContainerLibraryItemFragment,
  ) => ReactNode | undefined;
};

export type TrainingResourceLibrary = Omit<
  LibraryContainerLibraryItemFragment,
  "trainingResource"
> & {
  trainingResource: LibraryTrainingResourceFragment;
};

function isTrainingResourceLibrary(
  libraryItem: LibraryContainerLibraryItemFragment,
): libraryItem is TrainingResourceLibrary {
  return !!libraryItem.trainingResource;
}

export type CourseLibrary = Omit<
  LibraryContainerLibraryItemFragment,
  "course"
> & {
  course: LibraryCourseFragment;
};

function isCourseLibrary(
  libraryItem: LibraryContainerLibraryItemFragment,
): libraryItem is CourseLibrary {
  return !!libraryItem.course;
}

export type PathLibrary = Omit<LibraryContainerLibraryItemFragment, "path"> & {
  path: LibraryPathFragment;
};

function isPathLibrary(
  libraryItem: LibraryContainerLibraryItemFragment,
): libraryItem is PathLibrary {
  return !!libraryItem.path;
}

export type SkillLibrary = Omit<
  LibraryContainerLibraryItemFragment,
  "skill"
> & {
  skill: LibrarySkillFragment;
};

function isSkillLibrary(
  libraryItem: LibraryContainerLibraryItemFragment,
): libraryItem is SkillLibrary {
  return !!libraryItem.skill;
}

const useLibraryTableRowRenderFunctions = (): Return => {
  const userContextState = useUser();
  const getFormattedDate = useFormattedDateInTable();

  const renderTitleAndSubtitle = useCallback(
    (x: LibraryItemTitleFragment, subtitle?: string): ReactNode => {
      const title = getLibraryItemTitle(x);

      return (
        <AutoLayout
          direction={"vertical"}
          flex={1}
          alignmentVertical={"center"}
        >
          <Text type="P2" ellipsis fontWeight={"Medium"}>
            {title}
          </Text>
          {subtitle ? (
            <>
              <Text color={deprecatedTones.gray8} type="P3" ellipsis>
                {subtitle}
              </Text>
            </>
          ) : null}
        </AutoLayout>
      );
    },
    [],
  );

  const renderPublishedStatus = useCallback(
    (x: LibraryContainerLibraryItemFragment): string | null => {
      let publishedStatus = null;
      if (x.path || x.course || x.skill) {
        return x.publishState ? PUBLISH_STATE_COPY[x.publishState] : null;
      } else if (x.orgPremiumContentConfig) {
        publishedStatus = getOrgPremiumContentConfigFragmentStatus(
          x.orgPremiumContentConfig,
        );
      } else if (x.orgSharpConfig) {
        return getOrgSharpConfigStatus(x.orgSharpConfig);
      }
      return publishedStatus;
    },
    [],
  );

  const renderTags = useCallback(
    (x: LibraryContainerLibraryItemFragment): ReactNode => {
      return isSpecialContent(x) ? null : <LibraryItemTagCell item={x} />;
    },
    [],
  );

  const renderLastEdited = useCallback(
    (x: LibraryContainerLibraryItemFragment): ReactNode => {
      const lastEdited = getLibraryItemEditedAt(x);

      return (
        <AutoLayout
          direction={"vertical"}
          flex={1}
          alignmentVertical={"center"}
        >
          {lastEdited ? getFormattedDate(lastEdited) : null}
        </AutoLayout>
      );
    },
    [getFormattedDate],
  );
  const renderArchivedAt = useCallback(
    (x: LibraryContainerLibraryItemFragment): ReactNode => {
      const archivedAt = getLibraryItemArchivedAt(x);
      return archivedAt ? getFormattedDate(archivedAt) : "--";
    },
    [getFormattedDate],
  );

  const renderAvatar = useCallback(
    (
      x: LibraryItemAvatarFragment,
      size: ContentAvatarSize = "32px",
    ): ReactNode | undefined => {
      let id: number | string = -1;
      let coverImage: CoverImage | undefined = {} as CoverImage;
      if (x.orgSharpConfig) {
        id = x.orgSharpConfig.id;
        coverImage = { id } as CoverImage;
      } else if (x.orgPremiumContentConfig) {
        id = x.orgPremiumContentConfig.id;
        coverImage =
          x.orgPremiumContentConfig.premiumContentConfig.catalogPath?.path
            .libraryItem.coverImage || undefined;
      } else if (x.trainingResource) {
        coverImage = {
          id: x.trainingResource.id,
          imageUrls: x.trainingResource.media.imageUrls,
        };
      } else {
        id = x.id;
        coverImage = x.coverImage || undefined;
      }
      return (
        <ContentAvatar
          size={size}
          contentType={getLibraryItemContentType(x.type)}
          style={{ borderRadius: 8 }}
          coverImage={coverImage}
          media={x.trainingResource?.media}
          defaultBackgroundHashKey={id}
        />
      );
    },
    [],
  );

  const renderOverflowMenu = useCallback(
    (x: LibraryContainerLibraryItemFragment): ReactNode | undefined => {
      const itemSharingPermissions = getLibraryItemSharingPermissions(x);
      const itemOwnerId = getLibraryItemOwnerId(x);
      if (isSpecialContent(x)) {
        return null;
      }
      if (
        userContextState?.user?.userType === UserType.Admin ||
        (!!itemSharingPermissions &&
          userHasEditPermissions(
            itemSharingPermissions,
            userContextState,
            itemOwnerId,
          ))
      ) {
        if (isCourseLibrary(x)) {
          return <CourseOverflowMenu libraryItem={x} isVisible rotateIcon />;
        } else if (isPathLibrary(x)) {
          return <PathOverflowMenu libraryItem={x} isVisible rotateIcon />;
        } else if (isSkillLibrary(x)) {
          return <SkillOverflowMenu libraryItem={x} isVisible rotateIcon />;
        } else if (isTrainingResourceLibrary(x)) {
          return (
            <TrainingResourceOverflowMenu
              libraryItem={x}
              isVisible
              rotateIcon
            />
          );
        }
      }
      return null;
    },
    [userContextState],
  );

  return {
    renderTitleAndSubtitle,
    renderPublishedStatus,
    renderTags,
    renderLastEdited,
    renderArchivedAt,
    renderAvatar,
    renderOverflowMenu,
  };
};

export default useLibraryTableRowRenderFunctions;
